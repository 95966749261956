<template>
  <header id="page-header">
    <h1 class="h1">Gather Support</h1>
  </header>
  <div class="container py-5">
    <!-- "Use consultations to build connections.." Section -->
    <div class="row">
      <div class="col">
        <h3 class="h3">Use consultations to build connections and gather support</h3>
        <p class="p">One of the most important and rewarding parts of building your Mosaic Village initiative is reaching out to and consulting with people in your community to:
          <ul>
            <li>Better understand their varying needs, interests and perceptions.</li>
            <li>Identify people who will champion your initiative and serve as key team members.</li>
            <li>Recruit partners and develop allies across organizations and sectors.</li>
          </ul>
        </p>
        <p class="p">The partners and allies consultation tools help you brainstorm, organize and track your outreach plan, and tailor your conversations to achieve your goals. </p>
      </div>
    </div>
    <!-- "Partners and Allies Worksheet" Section -->
    <div class="row">
      <div class="col">
        <h2 class="h2 mb-4 mt-4">Partners and Allies Worksheet</h2>
        <table class="table table-bordered mv-table">
          <thead class="mv-table__head">
            <tr>
              <th>Name</th>
              <th>Organization</th>
              <th>Role</th>
              <th>Type</th>
              <th>Purpose</th>
              <th>Support Sought</th>
              <th>Benefit Offered</th>
              <th>Know/Intro</th>
              <th>Contacted</th>
              <th>Will Meet</th>
              <th><span class="visually-hidden">Delete Row</span></th>
            </tr>
          </thead>
          <tbody class="mv-table__body">
            <tr class="mv-table__row" v-for="(entry, i) in partners" :key="i">
              <!-- "Name" Field -->
              <td class="mv-table__cell">
                <input
                  class="form-control form-control-sm mv-table__input mv-table__input--name"
                  v-model="partners[i].name"
                  type="text"
                  :id="'name' + i"
                  placeholder="Name..."
                  @blur="updatePartner(i, 'name', partners[i].name);">
                <!-- eslint-disable-next-line -->
                <label class="visually-hidden" :for="'name' + i">Name</label>
              </td>
              <!-- "Organization" Field -->
              <td class="mv-table__cell">
                <input
                  class="form-control form-control-sm mv-table__input mv-table__input--org"
                  v-model="partners[i].organization"
                  type="text"
                  :id="'organization' + i"
                  placeholder="Organization..."
                  @blur="updatePartner(i, 'organization', partners[i].organization);">
                <!-- eslint-disable-next-line -->
                <label class="visually-hidden" :for="'organization' + i">Organization</label>
              </td>
              <!-- "Role" Field -->
              <td class="mv-table__cell">
                <input
                  class="form-control form-control-sm mv-table__input"
                  v-model="partners[i].role"
                  type="text"
                  :id="'role' + i"
                  placeholder="Role..."
                  @blur="updatePartner(i, 'role', partners[i].role);">
                <!-- eslint-disable-next-line -->
                <label class="visually-hidden" :for="'role' + i">Role</label>
              </td>
              <!-- "Type" Field -->
              <td class="mv-table__cell">
                <!-- eslint-disable-next-line -->
                <label class="visually-hidden" :for="('type'+ i)">Type</label>
                <select
                  v-model="partners[i].type"
                  @blur="updatePartner(i, 'type', partners[i].type);"
                  class="form-select form-select-sm mv-table__select"
                  type="text"
                  :id="('type' + i)"
                  :name="('type' + i)">
                  <option
                    disabled
                    selected
                    value="0">
                    Type...
                  </option>
                  <option
                    v-for="(option, optionValue) in typeOpts"
                    :key="optionValue">
                    {{ option }}
                  </option>
                </select>
                <div
                  v-if="partners[i].type === 'Other'">
                  <!-- eslint-disable-next-line -->
                  <label class="visually-hidden" :for="('typeOther' + i)">Other Type: </label>
                  <input
                    v-model="typeOther[i]"
                    @blur="updateOther('typeOther', i, typeOther[i]);"
                    placeholder="Other Type..."
                    class="mv-table__input"
                    type="text"
                    :id="('typeOther' + i)"
                    :name="('typeOther' + i)">
                </div>
              </td>
              <!-- "Purpose" Field -->
              <td class="mv-table__cell">
                <!-- eslint-disable-next-line -->
                <label class="visually-hidden" :for="('purpose'+ i)">Purpose</label>
                <select
                  v-model="partners[i].purpose"
                  @blur="updatePartner(i, 'purpose', partners[i].purpose);"
                  class="form-select form-select-sm mv-table__select"
                  type="text"
                  :id="('purpose' + i)"
                  :name="('purpose' + i)">
                  <option
                    disabled
                    selected
                    value="0">
                    Purpose...
                  </option>
                  <option
                    v-for="(option, optionValue) in purposeOpts"
                    :key="optionValue">
                    {{ option }}
                  </option>
                </select>
                <div
                  v-if="partners[i].purpose === 'Other'">
                  <!-- eslint-disable-next-line -->
                  <label class="visually-hidden" :for="('purposeOther' + i)">Other Purpose: </label>
                  <input
                    v-model="purposeOther[i]"
                    @blur="updateOther('purposeOther', i, purposeOther[i]);"
                    placeholder="Other Purpose..."
                    class="mv-table__input"
                    type="text"
                    :id="('purposeOther' + i)"
                    :name="('purposeOther' + i)">
                </div>
              </td>
              <!-- "Support Sought" Field -->
              <td class="mv-table__cell">
                <!-- eslint-disable-next-line -->
                <label class="visually-hidden" :for="('support'+ i)">Support Sought</label>
                <select
                  v-model="partners[i].support"
                  @blur="updatePartner(i, 'support', partners[i].support);"
                  class="form-select form-select-sm mv-table__select"
                  type="text"
                  :id="('support' + i)"
                  :name="('support' + i)">
                  <option
                    disabled
                    selected
                    value="0">
                    Support Sought...
                  </option>
                  <option
                    v-for="(option, optionValue) in supportOpts"
                    :key="optionValue">
                    {{ option }}
                  </option>
                </select>
                <div
                  v-if="partners[i].support === 'Other'">
                  <!-- eslint-disable-next-line -->
                  <label class="visually-hidden" :for="('supportOther' + i)">Other Support Sought: </label>
                  <input
                    v-model="supportOther[i]"
                    @blur="updateOther('supportOther', i, supportOther[i]);"
                    placeholder="Other Support Sought..."
                    class="mv-table__input"
                    type="text"
                    :id="('supportOther' + i)"
                    :name="('supportOther' + i)">
                </div>
              </td>
              <!-- "Benefit Offered" Field -->
              <td class="mv-table__cell">
                <!-- eslint-disable-next-line -->
                <label class="visually-hidden" :for="('benefit'+ i)">Benefit Offered</label>
                <select
                  v-model="partners[i].benefit"
                  @blur="updatePartner(i, 'benefit', partners[i].benefit);"
                  class="form-select form-select-sm mv-table__select"
                  type="text"
                  :id="('benefit' + i)"
                  :name="('benefit' + i)">
                  <option
                    disabled
                    selected
                    value="0">
                    Benefit Offered...
                  </option>
                  <option
                    v-for="(option, optionValue) in benefitOpts"
                    :key="optionValue">
                    {{ option }}
                  </option>
                </select>
                <div
                  v-if="partners[i].benefit === 'Other'">
                  <!-- eslint-disable-next-line -->
                  <label class="visually-hidden" :for="('benefitOther' + i)">Other Benefit Offered: </label>
                  <input
                    v-model="benefitOther[i]"
                    @blur="updateOther('benefitOther', i, benefitOther[i]);"
                    placeholder="Other Benefit Offered..."
                    class="mv-table__input"
                    type="text"
                    :id="('benefitOther' + i)"
                    :name="('benefitOther' + i)">
                </div>
              </td>
              <!-- "Know/Intro" Field -->
              <td class="mv-table__cell">
                <!-- eslint-disable-next-line -->
                <label class="visually-hidden" :for="('know'+ i)">Know/Intro</label>
                <select
                  v-model="partners[i].know"
                  @blur="updatePartner(i, 'know', partners[i].know);"
                  class="form-select form-select-sm mv-table__select"
                  type="text"
                  :id="('know' + i)"
                  :name="('know' + i)">
                  <option
                    disabled
                    selected
                    value="0">
                    Know/Intro...
                  </option>
                  <option
                    v-for="(option, optionValue) in knowIntroOpts"
                    :key="optionValue">
                    {{ option }}
                  </option>
                </select>
                <div
                  v-if="partners[i].know === 'No' || partners[i].know === 'Can get intro from...'">
                  <!-- eslint-disable-next-line -->
                  <label class="visually-hidden" :for="('knowIntroOther' + i)">No, will get intro from </label>
                  <input
                    v-model="knowIntroOther[i]"
                    @blur="updateOther('knowIntroOther', i, knowIntroOther[i]);"
                    placeholder="No, will get intro from..."
                    class="mv-table__input"
                    type="text"
                    :id="('knowIntroOther' + i)"
                    :name="('knowIntroOther' + i)">
                </div>
              </td>
              <!-- "Contacted" Field -->
              <td class="mv-table__cell align-middle">
                <input
                  class="form-control form-control-sm mv-table__input"
                  v-model="partners[i].contacted"
                  :id="'contacted' + i"
                  type="date"
                  @blur="updatePartner(i, 'contacted', partners[i].contacted);">
                <!-- eslint-disable-next-line -->
                <label class="visually-hidden" :for="'contacted' + i">Contacted</label>
              </td>
              <!-- "Will Meet" Field -->
              <td class="mv-table__cell align-middle">
                <input
                  class="form-control form-control-sm mv-table__input"
                  v-model="partners[i].meet"
                  :id="'meet' + i"
                  type="date"
                  @blur="updatePartner(i, 'meet', partners[i].meet);">
                <!-- eslint-disable-next-line -->
                <label class="visually-hidden" :for="'meet' + i">Meet</label>
              </td>
              <!-- Delete Row -->
              <td class="mv-table__cell d-flex align-items-center justify-content-center">
                <button class="btn btn-outline mv-icon-btn" aria-label="Delete Partner" @click="deletePartner(i);">
                  <i class="bi bi-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn-sm btn-secondary float-end" aria-label="Add Partner" @click="addPartner()"><i class="bi bi-plus"></i> Add Row</button>
      </div>
    </div>
  </div>
  <!-- Partners and Allies Consultation Guide -->
  <div class="container pt-1 pb-5">
    <h3 class="h3">Partners and Allies Consultation Guide</h3>
    <h6 class="h6 fst-italic">This guide is an outline, not a script. Feel free to personalize it so it reflects your voice and your goals.</h6>
    <!-- Introduction -->
    <div class="row mt-lg-3">
      <h6 class="h6 fw-bold mt-2">Introduction</h6>
      <p class="p">Thank you for meeting with me today. [<span class="fst-italic">if introduced by a shared connection</span>: I’m grateful &lt;<span class="fst-italic">friend name</span>&gt; was able to connect us]</p>
      <p class="p">I wanted to talk to you about a project called Mosaic Village that we are launching here in &lt;<span class="fst-italic">city name</span>&gt;.</p>
      <p class="p">I’m interested in hearing your perspectives, and I’d also like to share with you how this approach can help our community.</p>
    </div>
    <!-- Overview -->
    <div class="row">
      <h6 class="h6 fw-bold mt-2">Overview</h6>
      <p class="p">The Mosaic Village approach uses the collective impact model, and the active engagement of youth, to tackle the unique challenges we face in addressing youth homelessness.</p>
      <p class="p">Collective impact brings together partners across organizations and sectors to tackle complex social issues by coordinating their specialized individual efforts to build a more effective, integrated and holistic approach.</p>
      <p class="p">Meaningfully engaging youth helps ground our work in the realities young people face, so we can focus on meeting kids’ needs.</p>
      <p class="p">What I really like about this approach is that it’s efficient; it builds on our strengths—what we’re already doing well—but helps us use the resources we have more effectively. We’ll be better able to keep kids in school and off the streets.</p>
    </div>
    <!-- Discussion -->
    <div class="row">
      <h6 class="h6 fw-bold mt-2">Discussion</h6>
      <p class="p fst-italic">Use these prompts as a starting point to learn about the particular interests and concerns of each person you talk to. This will deepen your understanding of community perceptions in general, and help you learn about the specific concerns and interests of each person you talk to. This will help you tailor your message, figure out where their interests and the project’s needs intersect, and make a more informed ask.</p>
      <ol class="mv-ol">
        <li>What have you noticed about homelessness among young people here in &lt;city name&gt;?</li>
        <li>Do you think the situation is getting better, getting worse, or staying the same?</li>
        <li>Which organization(s) are responsible for improving our situation? Who else can play a role?</li>
        <li>What concerns you most about youth homelessness in our community?</li>
        <li>What do you see as the greatest priority for an effort to address youth homelessness?</li>
        <li>Who else do you think we should talk to?</li>
      </ol>
    </div>
    <!-- Response -->
    <div class="row">
      <h6 class="h6 fw-bold mt-2">Response</h6>
      <p class="p">It sounds like you’re concerned about/interested in...[<span class="fst-italic">reflect back some of the key points of their responses, and talk about the ways you believe Mosaic Village will help address those specific issues</span>].</p>
    </div>
    <!-- Project Status -->
    <div class="row">
      <h6 class="h6 fw-bold mt-2">Project Status</h6>
      <p class="p fst-italic">Quick update on what you’ve accomplished so far, what you’re currently working on, and what’s next.</p>
    </div>
    <!-- The Ask -->
    <div class="row">
      <h6 class="h6 fw-bold mt-2">The Ask</h6>
      <p class="p">We would love to have you be part of this effort. Because of [something specific about their role, characteristics, interests, organization], I was hoping you would be interested in [serving on the steering committee, becoming a partner in our Rapid Access Network, providing staff support, helping to get the word out, etc.].</p>
      <p class="p">Here’s what that would entail…</p>
    </div>
    <!-- Wrap Up -->
    <div class="row">
      <h6 class="h6 fw-bold mt-2">Wrap Up</h6>
      <p class="p">What do you think about what you’ve heard so far? What questions do you still have?</p>
      <p class="p">Thank you for meeting with me, and for [agreeing to serve as…/considering supporting us by…]. I’ll follow up with you on [date] about next steps.</p>
    </div>
    <!-- Post-Consultation Summary -->
    <div class="row">
      <h6 class="h6 fw-bold mt-2">Post-Consultation Summary</h6>
      <p class="p fst-italic">Immediately after your discussion, make note of what you learned in the spaces below:
      </p>
      <ul class="mv-ul">
        <li>Most important takeaway</li>
        <li>Pain points</li>
        <li>What interests them most in the project</li>
        <li>Overall attitude toward project</li>
        <li>Referrals</li>
        <li>What they are willing to contribute</li>
        <li>Did they commit?</li>
        <li>Follow up plan</li>
        <li>Any other thoughts</li>
      </ul>
    </div>
    <div class="row">
      <div class="d-flex justify-content-center">
        <a href="/docs/Partners-and-Allies-Consultation-Guide.docx" class="btn btn-primary">Download Consultation Guide Template</a>
      </div>
    </div>
  </div>
  <!-- Page Navigation -->
  <div class="container py-5">
    <div class="row">
      <div class="col">
        <router-link
          tag="button"
          class="btn btn-primary float-start"
          to="/get-ready">
          <i class="bi bi-chevron-left"></i>
        </router-link>
        <router-link
          tag="button"
          class="btn btn-primary float-end"
          to="/core-team">
          <i class="bi bi-chevron-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'GatherSupportPage',
  setup() {
    const store = useStore();
    return {
      typeOpts: computed(() => store.state.partners.typeOpts),
      purposeOpts: computed(() => store.state.partners.purposeOpts),
      supportOpts: computed(() => store.state.partners.supportOpts),
      knowIntroOpts: computed(() => store.state.partners.knowIntroOpts),
      benefitOpts: computed(() => store.state.partners.benefitOpts),
      typeOther: computed(() => store.state.partners.typeOther),
      purposeOther: computed(() => store.state.partners.purposeOther),
      supportOther: computed(() => store.state.partners.supportOther),
      benefitOther: computed(() => store.state.partners.benefitOther),
      knowIntroOther: computed(() => store.state.partners.knowIntroOther),
      partners: computed(() => store.state.partners.partners),
      addPartner: () => store.dispatch('partners/addPartner'),
      updatePartner: (id, prop, value) => store.dispatch('partners/updatePartner', { id, prop, value }),
      updateTypeOther: (id, value) => store.dispatch('partners/updateTypeOther', { id, value }),
      deletePartner: (id) => {
        const c = confirm('Are you sure you want to delete this record?');
        if (c === true) {
          store.dispatch('partners/deletePartner', { id });
        }
      },
    };
  },
};
</script>
<style lang="scss" scoped>
// Buttons

h2, h3 {
  font-size: 1.25rem;
  font-weight: 700;
}
.mv-icon-btn {
  padding: 1px 4px;
  i {
    font-size: 1.1rem;
  }
}
.btn-info,
.btn-secondary,
.btn-success {
  &,
  &:link,
  &:visited,
  &:active,
  &:hover {
    color: $light;
  }
}
.btn-danger,
.btn-danger i {
  &,
  &:link,
  &:visited,
  &:active,
  &:hover {
    color: $light;
  }
}

// Form Elements
.vue-select {
  padding: 0.375rem 2.25rem 0.375rem .75rem;
  width: 100%;
  line-height: 1.5;
  color: #00254A;
  background-color: ghostwhite;
}
.vue-select .vue-input input, .vue-select .vue-input {
  font-size: 1rem !important;
  background-color: ghostwhite;
}

// Icons
.mv-icon {
  color: $body-color;
}

// Tables
table td:nth-child(8), table td:nth-child(9) {
  max-width: 180px !important;
}
.table > :not(:first-child) {
  border-bottom: inherit;
}
.mv-table {
  border: $form-table-border;
  font-size: $form-table-font-size;
}
.mv-table thead {
  border-bottom: $form-table-border;
}
.mv-table th {
  color: $form-table-label-text-color;
}
.mv-table__row {
  td,
  .mv-table__cell {
    padding: $form-table-cell-padding;
  }
  .mv-table__input {
    background: $form-table-input-bg;
    border: $input-group-input-border-base;
    border-radius:3px;
    color: $input-group-input-text-color;
    font-size: $input-group-input-font-size;
    height: 100%;
    padding: 4px 2px;
    transition: $transition-default;
    width: $form-table-input-width;
    &:focus,
    &:focus-visible {
      border-color: $info;
      box-shadow: none;
      outline: none;
    }
    &--name {
      min-width:120px;
    }
    &--org {
      min-width:130px;
    }
  }
  .mv-table__input[type=date] {
    font-size:12px;
    max-width:114px;
    width:auto;
  }
  .mv-table__select {
    border: $input-group-input-border-base;
    &:focus,
    &:focus-visible {
      border-color: $info;
      box-shadow: none;
      outline: none;
    }
  }
}

// Lists
.mv-ol,
.mv-ul {
  list-style-position: inside;

  li {
    margin:5px 0;
  }
}
</style>
